//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: 											#ffffff;

// Theme colors
// Primary
$primary:       									#009ef7; //  5c5c5c
$primary-active:    								#0095e8;
$primary-light:    								    #f1faff;
$primary-light-dark:    							#212e48;
$primary-inverse:  									$white;

// Success
$success:       									#50cd89;
$success-active:    								#47be7d;
$success-light:    									#e8fff3;
$success-light-dark:    							#1c3238;
$success-inverse:  									$white;

// Info
$info:       									    #5c5c5c; //7239ea
$info-active:    									#5014d0;
$info-light:    									#f8f5ff;
$info-light-dark:    								#2f264f;
$info-inverse:  									$white;

// Danger
$danger:       									    #d01834; //f1416c
$danger-active:    									#d9214e; //d9214e
$danger-light:    									#fff5f8; //fff5f8
$danger-light-dark:    								#3a2434;
$danger-inverse:  									$white;

// Warning
$warning:       									#ffc700;
$warning-active:    								#f1bc00;
$warning-light:    									#fff8dd;
$warning-light-dark:    							#392f28;
$warning-inverse:  									$white;


// UCJ
$ucj:       									#d01834;
$ucj-active:    								#5c5c5c;
$ucj-light:    									#f5b7ad;
$ucj-light-dark:    							#e36562;
$ucj-inverse:  									$white;